
import { logOut, idCard, school } from 'ionicons/icons';
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonIcon,
  IonNavLink,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import store from './store';
import { Device } from '@capacitor/device';
import { logoYoutube } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonIcon,
    IonNavLink,
  },

  mounted() {
    Device.getLanguageCode().then((res) => {
      if (res.value.includes('-')) {
        const language = res.value.split('-')[0];
        this.$i18n.locale = language;
      } else {
        this.$i18n.locale = res.value;
      }
    });
  },

  setup() {
    const selectedIndex = ref(0);
    const deviceInfo = ref();

    const { t, locale } = useI18n();

    const getDeviceInfo = async () => {
      deviceInfo.value = await Device.getInfo();
    };

    getDeviceInfo();

    const appPages = [
      {
        title: 'mein_konto',
        url: '/mein-konto',
      },
      {
        title: 'teilnahmen',
        url: '/teilnahmen',
      },
    ];

    const path = window.location.pathname;
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.url.toLowerCase() == path.toLowerCase()
      );
    }

    const route = useRoute();

    const logout = function () {
      store.commit('logout');
    };

    const changeLanguage = function (lang) {
      locale.value = lang;
    };

    return {
      selectedIndex,
      appPages,
      logOut,
      deviceInfo,
      logoYoutube,
      idCard,
      school,
      logout,
      t,
      locale,
      changeLanguage,
      isSelected: (url: string) => (url === route.path ? 'selected' : ''),
    };
  },

  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated;
    },
  },
});
