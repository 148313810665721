import { createStore } from 'vuex';
import router from './router/index';

const store = createStore({
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
    email: '',
    password: '',
    auth: localStorage.getItem('auth') || '',
    lang: '',
  },
  mutations: {
    login(state, payload) {
      state.email = payload.email;
      state.password = payload.password;
    },

    logout() {
      localStorage.removeItem('auth');
      router.go(0);
    },

    authRequest: (state) => {
      state.status = 'loading';
    },
    authSuccess: (state, payload) => {
      state.status = 'success';
      store.commit('setAuth', payload);
      router.go(0);
    },
    setAuth: (state, payload) => {
      const now = new Date();
      const item = {
        user: payload,
        expiry: now.getTime() + 3600 * 1000,
      };
      localStorage.setItem('auth', JSON.stringify(item));
    },

    authError: (state) => {
      state.status = 'error';
    },
  },
  getters: {
    isAuthenticated: function (state) {
      if (state.auth.length == 0) {
        return false;
      }
      const item = JSON.parse(state.auth);
      const now = new Date();

      if (now.getTime() < item.expiry) {
        return true;
      } else {
        store.commit('logout');
        return false;
      }
    },
    authStatus: (state) => state.status,
  },
  actions: {},
});

export default store;
