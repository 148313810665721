import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/mein-konto',
    component: () => import('../views/MeinKonto.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/teilnahmen',
    component: () => import('../views/Teilnahmen.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next('/login');
    }
  } else {
    if (store.getters.isAuthenticated) {
      next('/mein-konto');
    } else {
      next();
    }
  }
});

export default router;
