import { createApp } from 'vue';
import store from './store';

import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
import './registerServiceWorker';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.scss';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createI18n } from 'vue-i18n';
import { globalizationList } from './i18n/globalizationData';

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: globalizationList,
  preserveDirectiveContent: true,
  legacy: false,
});

const app = createApp(App).use(IonicVue).use(router).use(store).use(i18n);

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgO_eB0Z1tgVlFumQa8wAI8SjWNQz7Uek',
    libraries: 'places',
  },
});

router.isReady().then(() => {
  app.mount('#app');
});
